<template>
  <van-pull-refresh v-model="isPulling" @refresh="onRefresh">
    <van-search
      v-model="searchKey"
      @search="toSearch"
      @clear="toClear"
      placeholder="请输入搜索关键词"
    />
    <div class="news-wrap">
      <van-list
        class="news-list"
        v-model="isMore"
        :finished="finished"
        @load="loadMore"
      >
        <div
          class="news-item"
          v-for="item in newsList"
          :key="item.articel_id"
          @click="toNewsDetail(item)"
        >
          <label>{{ item.title }}</label>
          <img :src="item.cover" mode="aspectFill" />
        </div>
      </van-list>
    </div>
  </van-pull-refresh>
</template>

<script>
import { fetchList } from "@/network/API";
import { List, PullRefresh, Button } from "vant";
export default {
  components: {
    List,
    PullRefresh,
    Button,
  },
  data() {
    return {
      newsList: [],
      title: "",
      typeId: "",
      searchKey: "",
      subTypeId: "",

      page: 1,
      isPulling: false,
      isMore: false,
      finished: false,
    };
  },
  created() {
    if (this.$route.query.tid) {
      this.typeId = this.$route.query.tid;
    }
    if (this.$route.query.name) {
      this.title = this.$route.query.name;
    }
    if (this.$route.query.sid) {
      this.subTypeId = this.$route.query.sid;
    }
    // this.$eventBus.$emit("updateTitle", this.title);
    this.$store.commit("updateTitle", {
      pushed: true,
    });
    this.$store.commit("updateNavTitle", {
      navTitle: this.title,
    });
    this.getArticleList();
  },
  methods: {
    toClear() {
      this.searchKey = "";
      this.page = 1;
      this.getArticleList();
    },
    toSearch(val) {
      this.searchKey = val;
      this.page = 1;
      this.getArticleList();
    },
    loadMore() {
      this.page += 1;
      this.getArticleList(true);
    },
    onRefresh() {
      this.finished = false;
      this.page = 1;
      this.getArticleList();
    },
    async getArticleList(more = false) {
      this.isLoading = true;
      let data = await fetchList({
        page_size: 10,
        page: this.page,
        type_id: this.typeId,
        sub_type_id: this.subTypeId,
        keyword: this.searchKey,
      });
      this.isPulling = false;
      this.isMore = false;
      if (more) {
        this.newsList = [...this.newsList, ...data.list];
      } else {
        this.newsList = data.list;
      }
      if (this.newsList.length >= parseInt(data.total)) {
        this.finished = true;
      } else {
        this.finished = false;
      }
    },
    toNewsDetail(item) {
      // console.log(item);
      this.$router.push({
        name: "Detail",
        params: {
          id: item.article_id,
        },
      });
    },
  },
};
</script>

<style></style>
